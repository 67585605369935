$fontFrank: "Frank Ruhl Libre";
$fontHelvetica: "helvetica_neue";
$colorBlack: #000000;
$colorWhite: #ffffff;
$colorGray: #ededed;
$colorDarkGray: #c4c4c4;
$colorGray979797: #979797;
$colorHover: #9c9c9c;
// Screen Size
$desktopS: 1440px;
$tabletScreen: 1108px;
$mobileScreen: 600px;

%subTitle14px {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

%subTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-family: $fontFrank, $fontHelvetica;
}

%H1Desktop {
  font-weight: 400;
  font-size: 58px;
  line-height: 69px;
  font-family: $fontHelvetica;
  margin-bottom: 0;
  @media (max-width: $tabletScreen) {
    font-size: 54px;
    line-height: 64px;
  }
  @media (max-width: $mobileScreen) {
    font-size: 32px;
    line-height: 38px;
  }
}

%H2Desktop {
  font-weight: 400;
  font-size: 46px;
  line-height: 55px;
  font-family: $fontHelvetica;
  margin-bottom: 0;
  @media (max-width: $tabletScreen) {
    font-size: 28px;
  }
  @media (max-width: $mobileScreen) {
    font-size: 22px;
    line-height: 26px;
  }
}

%H3Desktop {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  font-family: $fontHelvetica;
  @media (max-width: $tabletScreen) {
    font-size: 22px;
  }
}

%bodyDesktop {
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  font-family: $fontHelvetica;
  @media (max-width: $tabletScreen) {
    font-size: 22px;
  }
}

%pDeskTop {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: $fontHelvetica;
  margin-bottom: 0;
}

* {
  margin: 0px;
  padding: 0px;
  font-size: 100%;
  // vertical-align: baseline;
}

.desktopOnly {
  display: block;

  @media (max-width: $tabletScreen) {
    display: none;
  }
}

.desktopMenu {
  display: block;

  @media (max-width: 1301px) {
    display: none;
  }
}

.tabletMobileMenu {
  display: none;

  @media (max-width: 1301px) {
    display: block;
  }
}

.tabletOnly {
  display: none;

  @media (max-width: $tabletScreen) {
    display: block;
  }

  @media (max-width: $mobileScreen) {
    display: none;
  }
}

.mobileOnly {
  display: none;

  @media (max-width: $mobileScreen) {
    display: block;
  }
}

.desktopTablet {
  display: block;

  @media (max-width: $mobileScreen) {
    display: none;
  }
}

.tabletMobile {
  display: none;

  @media (max-width: $tabletScreen) {
    display: block;
  }
}

.desktopMobile {
  display: block;

  @media (max-width: $tabletScreen) {
    display: none;
  }

  @media (max-width: $mobileScreen) {
    display: block;
  }
}

a {
  text-decoration: none;
  color: $colorBlack;
}

a:visited {
  // color: $colorBlack !important;;
}

%button {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid $colorBlack;
  color: $colorBlack;
  padding: 22px 32px;
  background-color: transparent;
  font-family: $fontFrank, $fontHelvetica;
}

ul,
li {
  // margin: 0 !important;
  // padding: 0 !important;
  // list-style: none;
}

.container {
  @media (max-width: $mobileScreen) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

input,
select {
  -webkit-appearance: none;
  border-radius: 0;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:root {
  --color-primary: #002626;
  --color-secondary: #f0f7ee;
  --duration: 2s;
  --nav-duration: calc(var(--duration) / 4);
  --ease: cubic-bezier(0, 0, 1, 1);
  --space: 1rem;
  // --font-primary: "Helvetica", sans-serif;
  // --font-heading: "Merriweather", serif;
  --font-size: 1.125rem;
  --line-height: 1.5;
}

.spaceMenu {
  width: 100%;
  height: 80px;
  display: none;
}
.spaceMenuActive {
  display: block;
}
.sectionHeader {
  // position: fixed;
  width: 100%;
  // background-color: $colorWhite;
  z-index: 2;
  .appHeader {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
    align-items: center;
    @media (max-width: $mobileScreen) {
      padding: 32px 0px;
    }

    .logo {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      max-width: 300px;
      top: 30px;
      @media (max-width: $mobileScreen) {
        max-width: 180px;
        top: 28px;
      }
      img {
        @media (max-width: $mobileScreen) {
          height: 16px;
        }
      }
    }
    .wrapLange {
      display: flex;
      gap: 4px;
      align-items: center;
      ul {
        display: flex;
        gap: 4px;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
          color: $colorGray;
          list-style: none;
          @extend %subTitle14px;
          a {
            text-decoration: none;
            img {
              display: none;
            }
          }
          .active {
            // color: $colorBlack;
            img {
              display: block;
            }
          }
          img {
            width: 28px;
            height: 28px;
          }
        }
        .thActive {
          color: $colorBlack;
          display: none;
        }
        .thActiveLeft {
          display: block;
          pointer-events: none;
        }
      }
      a {
        display: flex;
        gap: 4px;
        align-items: center;
        color: $colorGray;
        @extend %subTitle14px;
        img {
          max-width: 25px;
        }
      }
      .dropdownLangMobile {
        display: flex;
        align-items: center;
        gap: 7px;
        h3 {
          @extend %subTitle14px;
          margin-bottom: 0;
        }
        .iconLang {
          max-width: 25px;
          cursor: pointer;
          @media (max-width: $mobileScreen) {
            max-width: 16px;
          }
        }

        .iconLang:hover {
          @media (max-width: $tabletScreen) {
            -moz-animation: spinHorizontal 0.8s infinite linear;
            -o-animation: spinHorizontal 0.8s infinite linear;
            -webkit-animation: spinHorizontal 0.8s infinite linear;
            animation: spinHorizontal 0.8s infinite linear;
          }
        }

        @-moz-keyframes spinHorizontal {
          0% {
            -moz-transform: rotateY(0deg);
          }

          100% {
            -moz-transform: rotateY(360deg);
          }
        }

        @keyframes spinHorizontal {
          0% {
            transform: rotateY(0deg);
          }
          100% {
            transform: rotateY(360deg);
          }
        }

        @-ms-keyframes spinHorizontal {
          0% {
            -ms-transform: rotateY(0deg);
          }
          100% {
            -ms-transform: rotateY(360deg);
          }
        }

        @-o-keyframes spinHorizontal {
          0% {
            -o-transform: rotateY(0deg);
          }
          100% {
            -o-transform: rotateY(360deg);
          }
        }

        @-webkit-keyframes spinHorizontal {
          0% {
            -webkit-transform: rotateY(0deg);
          }
          100% {
            -webkit-transform: rotateY(360deg);
          }
        }

        .thActive {
          display: block;
        }
        .enActive {
          display: none;
        }
        .containerEnActive {
          .thActive {
            display: none;
          }
          .enActive {
            display: block;
          }
        }
      }
      .langMobileActive {
        .iconDropdown {
          transform: rotate(180deg);
        }
      }
      .wrapMenuNavigationLang {
        width: 150px;
        background-color: $colorWhite;
        padding: 10px;
        position: fixed;
        filter: drop-shadow(0 0 0.75rem $colorGray);
        top: 80px;
        ul {
          display: block;
          li {
            display: flex;
            gap: 7px;
            margin-bottom: 20px !important;
            a {
              color: $colorBlack;
            }
          }
        }
      }
      p {
        color: $colorGray;
        @extend %subTitle14px;
      }
      button {
        img {
          display: none;
        }
      }
      .btnLangActive {
        a {
          color: $colorBlack;
        }
        img {
          display: block;
        }
      }
    }
  }
  .wrapMenuNavigation {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 22px 0;
    min-height: 100vh;
    // display: none;
    align-items: center;
    background-color: $colorBlack;
    overflow-y: scroll;
    z-index: 9;
    .wrapListMenuNavigation {
      ul {
        li {
          @extend %H2Desktop;
          color: $colorWhite;
          a {
            @extend %H2Desktop;
            color: $colorWhite;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .menuHam {
    .hambergerBox {
      width: 20px;
      height: 20px;
      position: relative;
      float: right;
      cursor: pointer;
      .hambergerBar {
        width: 20px;
        height: 3px;
        background-color: #000000;
        position: absolute;
      }
      .hambergerBar:nth-of-type(1) {
        top: 0px;
      }
      .hambergerBar:nth-of-type(2) {
        top: 10px;
      }
      .hambergerBar:nth-of-type(3) {
        top: 20px;
      }
      .hambergerBar:nth-of-type(1).activeHam {
        top: 10px;
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
        -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
        -webkit-transition-delay: 0.1s, 0.5s;
        transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
      }
      .hambergerBar:nth-of-type(2).activeHam {
        opacity: 0;
        transition: 0.5s ease-in-out;
      }
      .hambergerBar:nth-of-type(3).activeHam {
        top: 10px;
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.7s;
        -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.7s;
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
        -webkit-transition-delay: 0.1s, 0.7s;
        transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.7s;
      }
    }
    .menuHamActive {
      position: fixed;
      top: 40px;
      right: 62px;
      z-index: 99;
      .hambergerBar {
        background-color: $colorWhite;
        width: 19px;
      }
      .hambergerBar:nth-of-type(1) {
        top: 10px;
        transform: rotate(45deg);
        -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
        -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
        -webkit-transition-delay: 0.1s, 0.5s;
        transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
      }
      .hambergerBar:nth-of-type(2) {
        display: none;
      }
      .hambergerBar:nth-of-type(3) {
        top: 10px;
        transform: rotate(-45deg);
        -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.7s;
        -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.7s;
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
        -webkit-transition-delay: 0.1s, 0.7s;
        transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.7s;
      }
    }
    .animateMenuHead {
      -moz-transition: -moz-transform 0.3s ease 0.5s;
      -o-transition: -o-transform 0.3s ease 0.5s;
      -webkit-transition: -webkit-transform 0.3s ease;
      -webkit-transition-delay: 0.5s;
      transition: transform 0.3s ease 0.5s;
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .main-navigation-toggle {
    // position: fixed;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;

    + label {
      // position: fixed;
      top: calc(var(--space) * 1.5);
      right: calc(var(--space) * 2);
      cursor: pointer;
      z-index: 2;
    }
  }

  .icon--menu-toggle {
    --size: calc(1rem + 4vmin);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 40px;
    stroke-width: 6;
    // position: fixed;
    @media (max-width: $mobileScreen) {
      max-width: 22px;
      height: 22px;
    }
  }

  .icon-group {
    transform: translateX(0);
    transition: transform var(--nav-duration) var(--ease);
  }

  .icon--menu {
    stroke: $colorBlack;
  }

  .icon--close {
    stroke: $colorWhite;
    transform: translateX(-100%);
  }

  .main-navigation {
    position: fixed;
    top: 0;
    left: 0;
    padding: 50px 0;
    padding-top: 20px;
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    // transition: transform var(--nav-duration);
    // animation-timing-function: linear;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #d9d9d9;
      opacity: 0.3;
      transform-origin: 0 50%;
      z-index: -1;
    }

    ul {
      // @extend %H1Desktop;
      text-transform: uppercase;
      width: 100%;

      // height: 100%;
      // display: grid;
      // grid-template-columns: 1fr;
      li {
        @media (max-width: $tabletScreen) {
          margin-bottom: 0;
        }
        h1 {
          @extend %H1Desktop;
          @media (max-width: $tabletScreen) {
            line-height: 58px;
          }
        }
      }
    }

    .subMainMenu {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      // justify-content: flex-start;
      li {
        margin-bottom: 15px;
        @media (max-width: $mobileScreen) {
          margin-bottom: 0px;
        }
        h1 {
          @extend %pDeskTop;
          line-height: 22px;
          @media (max-width: $tabletScreen) {
            font-size: 12px;
            line-height: 19px;
          }
        }
      }
    }

    li {
      // --border-size: 1px;
      // border-color: #c4c4c4;
      // display: flex;
      // align-items: center;
      position: relative;
      // overflow: hidden;
      list-style: none;
      margin-bottom: 12px;

      &:after {
        content: "";
        // position: absolute;
        // bottom: 0;
        // left: 0;
        // width: 100%;
        // height: 1px;
        // background-color: #c4c4c4;
        // transform-origin: 0;
        // transform: translateX(-100%);
      }
    }

    a {
      display: inline-block;
      width: 100%;
      // max-width: 50%;
      margin: 0 auto;
      color: $colorWhite;
      line-height: 1;
      // padding: 10px;
      text-decoration: none;
      user-select: none;
      transform: translateX(100%);
      // text-align: center;
      // @media (max-width: $tabletScreen) {
      //   text-align: center;
      // }
    }
  }

  .menu-navigation {
    max-width: 50%;
    /* float: right; */
    position: absolute;
    right: 0;
    width: 100%;
    background-color: #000;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    animation: nav-bg var(--nav-duration) var(--ease) forwards;
    @media (max-width: $tabletScreen) {
      max-width: 100%;
    }
  }
  .main-content {
    margin: 6rem auto;
    max-width: 70ch;
    padding: 0 calc(var(--space) * 2);
    transform: translateX(0);
    transition: transform calc(var(--nav-duration) * 2) var(--ease);

    > * + * {
      margin-top: calc(var(--space) * var(--line-height));
    }
  }

  .main-navigation-toggle-open {
    ~ label .icon--menu-toggle {
      // position: fixed;
      // @media (max-width: $tabletScreen) {
      //   right: 15px;
      // }
      .icon-group {
        transform: translateX(100%);
      }
    }

    ~ .main-content {
      transform: translateX(10%);
    }

    ~ .main-navigation {
      transition-duration: 0s;
      transform: translateX(0%);

      @media (max-width: $tabletScreen) {
        transform: translateX(0);
      }
      &:after {
        // animation: nav-bg var(--nav-duration) var(--ease) forwards;
      }

      li:after {
        // animation: nav-line var(--duration) forwards;
      }

      a {
        animation: link-appear calc(var(--duration) / 2) var(--ease) forwards;
      }

      @for $i from 1 through 7 {
        li:nth-child(#{$i}) {
          &:after,
          a {
            // animation-delay: calc((var(--duration) / 2) * #{$i} * 0.125);
          }
        }
      }
    }
  }

  @keyframes nav-bg {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes nav-line {
    0% {
      transform: scaleX(0);
      transform-origin: 0 50%;
    }
    35% {
      transform: scaleX(1.001);
      transform-origin: 0 50%;
    }
    65% {
      transform: scaleX(1.001);
      transform-origin: 100% 50%;
    }
    100% {
      transform: scaleX(0);
      transform-origin: 100% 50%;
    }
  }

  @keyframes link-appear {
    0%,
    25% {
      transform: translateX(100%);
    }
    50%,
    100% {
      transform: translateX(0);
    }
  }

  .toggle {
    display: block;
    cursor: pointer;
    input {
      display: none;
      & + div {
        width: 20px;
        height: 17px;
        position: relative;
        div {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          transition: transform 0.5s ease;
          span {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            &:first-child {
              top: 0;
              &:before,
              &:after {
                top: 0;
              }
            }
            &:last-child {
              bottom: 0;
              &:before,
              &:after {
                bottom: 0;
              }
            }
            &:before,
            &:after {
              content: "";
              display: block;
              width: 47%;
              height: 3px;
              border-radius: 0px;
              background: $colorBlack;
              position: absolute;
              -webkit-backface-visibility: hidden;
              transition: transform 0.5s ease, border-radius 0.3s ease,
                background 0.4s ease;
            }
            &:before {
              left: 0;
              transform-origin: 0 50%;
              transform: translate(1px, 0) scaleX(1.1);
            }
            &:after {
              right: 0;
              transform-origin: 100% 50%;
              transform: translate(-1px, 0) scaleX(1.1);
            }
          }
        }
        svg {
          display: block;
          fill: none;
          stroke: $colorBlack;
          stroke-width: 3px;
          width: 44px;
          height: 44px;
          stroke-linecap: unset;
          position: absolute;
          left: 50%;
          top: 50%;
          margin: -22px 0 0 -22px;
          stroke-dasharray: 0 82.801 9 82.801;
          stroke-dashoffset: 82.801;
          transform-origin: 50% 50%;
          -webkit-backface-visibility: hidden;
          transform: scale(1);
          transition: stroke-dashoffset 0.5s ease, stroke-dasharray 0.6s ease,
            transform 0.5s ease, stroke 0.4s ease;
          &:nth-child(3) {
            transform: rotate(180deg) scale(1);
          }
        }
      }
      &:checked + div {
        div {
          transform: rotate(90deg);
          span {
            &:before,
            &:after {
              background: $colorWhite;
            }
            &:first-child {
              &:before {
                transform: rotate(45deg) translate(2.2px, -3px) scaleX(1.05);
              }
              &:after {
                transform: rotate(-45deg) translate(-2.2px, -3px) scaleX(1.05);
              }
            }
            &:last-child {
              &:before {
                transform: rotate(-45deg) translate(2.2px, 3px) scaleX(1.05);
              }
              &:after {
                transform: rotate(45deg) translate(-2.2px, 3px) scaleX(1.05);
              }
            }
          }
        }
        svg {
          stroke-dashoffset: 62;
          stroke-dasharray: 0 81.801 64 81.801;
          transform: rotate(90deg);
          stroke: $colorWhite;
          z-index: 2;
          &:nth-child(3) {
            transform: rotate(270deg);
          }
        }
      }
    }
  }
  .toggleMenuOpen {
    // margin-top: 15px;
    input + div {
      // margin-top: 10px;
      // transition: transform .5s ease;
      width: 17px;
      height: 12px;
    }
    input + div svg {
      margin: -14px 0 0 -14px;
      width: 28px;
      height: 28px;
    }
  }
}

.sectionHeaderActive {
  position: fixed;
}

.wrapApproach {
  padding: 130px 0 130px 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0 105px 0;
  }
  h2 {
    @extend %H1Desktop;
  }
  h3 {
    @extend %subTitle;
    margin-top: 10px;
    // margin-bottom: 30px;
  }
  .titleApproach {
    margin-bottom: 49px;
  }
  .wrapTextApproach {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 10px;
    span {
      @extend %H1Desktop;
      margin: 0;
      h4 {
        @extend %H1Desktop;
        margin: 0;
      }
    }
    span:last-child {
      span:last-child {
        // display: none;
        span:last-child {
          display: none;
        }
      }
    }
    p {
      @extend %H1Desktop;
      margin-left: 10px;
    }
    // .wrapArticleApproach {
    //   display: flex;
    //   // gap: 10px;
    //   span {
    //     @extend %H1Desktop;
    //     margin: 0;
    //     h4 {
    //       @extend %H1Desktop;
    //       margin: 0;
    //     }
    //   }
    //   p {
    //     @extend %H1Desktop;
    //     margin-left: 10px;
    //   }
    //   // span::after {
    //   //   content: ",";
    //   // }
    //   .textRun {
    //     // margin-left: 10px;
    //     display: flex;
    //   }
    // }
    .wrapArticleApproach:last-child {
      p {
        span {
          display: none;
        }
      }
    }
  }
}
.wrapTitleWorksHome {
  h3 {
    @extend %subTitle;
    text-align: center;
    margin-bottom: 37px;
    @media (max-width: $tabletScreen) {
      text-align: left;
    }
  }
}

.sectionWorksHome {
  // top: 0;
  // width: 100%;
  // overflow-y: scroll;
  background-color: $colorWhite;

  .wrapWorksHome {
    position: relative;
    // padding: 0 0 153px 0;
    // min-height: 100vh;

    .wrapTextLeftworksHome {
      // top: 10%;
      // left: 10%;
      // width: 50%;
      position: sticky;
      @media (max-width: $tabletScreen) {
        position: static;
      }
    }
    h3 {
      @extend %subTitle;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .wrapTextworksHome {
      position: sticky;
      top: 10%;
      left: 10%;
      padding-bottom: 300px;
      @media (max-width: $tabletScreen) {
        padding-bottom: 0px;
        position: static;
      }
      @media (max-width: $mobileScreen) {
      }
      ul {
        margin: 0;
        padding: 0;
      }
    }
    .textWorksActive {
      @media (max-width: $tabletScreen) {
        width: 100%;
        position: fixed;
        background-color: $colorWhite;
        left: 0;
        top: 0;
        padding-left: 10%;
        padding-top: 10%;
      }
    }
    .wrapSubTitleWorksHome {
      // position: sticky;
      // top: 10%;
      // left: 10%;
      border-right: none !important;
      h2 {
        // animation-name: slideUp;
        // animation-duration: 4s;
        // animation-timing-function: ease-in;
        // animation-fill-mode: forwards;
        // display: inline;
        @extend %bodyDesktop;
      }

      h2::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      h2 {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }

    .listWorksHomes {
      min-height: 99vh;
      // margin-bottom: 130px;
      @media (max-width: $tabletScreen) {
        min-height: 100%;
        margin-bottom: 130px;
      }
      @media (max-width: $mobileScreen) {
        margin-bottom: 0px;
      }
      a {
        // @extend %button;
        border: none;
        color: $colorBlack;
        text-decoration: none;
        text-align: left;
        font-family: $fontFrank, $fontHelvetica;
        margin-top: 0;
        display: block;
      }
      h1 {
        @extend %bodyDesktop;
        margin-bottom: 20px;
        @media (max-width: $tabletScreen) {
          // font-size: 40px;
        }
        @media (max-width: $mobileScreen) {
          // font-size: 24px;
        }
      }
    }

    .wrapCaseStudy {
      h3 {
        @extend %subTitle;
        margin-top: 29px;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          @extend %H1Desktop;
          color: $colorGray;
          cursor: pointer;
          //   line-height: normal;
          a {
            @extend %H1Desktop;
            text-decoration: none;
            color: $colorGray;
          }
        }
        .active {
          color: $colorBlack;
        }
      }
      .wrapListCaseStudyCase {
        button {
          @extend %H1Desktop;
          display: block;
          background-color: transparent;
          border-radius: 0px;
          border: none;
          color: $colorGray;
        }
        button:disabled {
          color: $colorBlack;
        }
      }
    }

    @keyframes slideUp {
      0%,
      50% {
        transform: translateY(100%);
        opacity: 0;
      }

      60%,
      100% {
        transform: translate(0px, 0px);
        opacity: 1;
      }
    }
    .wrapContentCaseStudy {
      img {
        max-width: 460px;
        // animation-name: slideUp;
        // animation-duration: 4s;
        // animation-timing-function: ease-in;
        // animation-fill-mode: forwards;
      }
      .itemContentCaseStudy {
        margin-bottom: 100px;
      }
      .itemContentCaseStudy:nth-child(even) {
        margin-left: 124px;
      }
    }
    img {
      width: 100%;
      margin-bottom: 26px;
    }
    h2 {
      @extend %H2Desktop;
    }
    .lineWorskHome {
      width: 1px;
      height: 100%;
      background-color: $colorDarkGray;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 0;
      @media (max-width: $tabletScreen) {
        display: none;
      }
    }
  }
  a {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 9px;
    button {
      @extend %button;
      min-width: 166px;
    }
    button:hover {
      background-color: $colorHover;
    }
  }
}

.triggers {
  // padding: 1rem;
  position: sticky;
  top: 20%;
  left: 10%;
  @media (max-width: $tabletScreen) {
    padding-bottom: 134px;
    position: static;
  }
  @media (max-width: $mobileScreen) {
    padding: 0;
    padding-bottom: 53px;
    position: static;
  }
  h1 {
    @media (max-width: $tabletScreen) {
      font-size: 54px;
    }
    @media (max-width: $mobileScreen) {
      font-size: 24px;
    }
  }
  .listActive {
    li {
      color: $colorBlack;
    }
  }
}
.triggers ul {
  // display: flex;
  // gap: 1rem;
  // list-style: none;
  // margin: 0;
  // padding: 0;
  button {
    // margin-bottom: 10px;
    // margin-top: 10px;
  }
  li {
    @extend %H3Desktop;
    color: $colorGray;
    text-align: left;
    line-height: 30px;
    // font-family: $fontFrank;
    margin-bottom: 14px;
    list-style: none;
    vertical-align: text-bottom;
    // max-height: 70px;
    // overflow: hidden;
    // line-height: 90px;
    span {
      line-height: 58px;
      display: inline;
      height: 58px;
    }
  }
}
.triggers button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
}
.triggers button.active {
  color: $colorBlack !important;
  li {
    color: $colorBlack !important;
  }
}

.sections {
  // height:  2000px;
  // overflow: auto;
}

.sections::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sections {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.sections {
  // width: 100%;
  // position: fixed;
  // overflow-y: scroll;
  // left: 0;
  // padding-left: 50%;
  section {
    // max-width: 460px;
    // min-height: 80vh;
    // padding-bottom: 170px;
    // height: 100vh;
    // height: 471px;
    // display: none;
    // padding-top: 20%;
    // padding: 1rem;
    // height: 100vh;
    @media (max-width: $tabletScreen) {
      padding-left: 0px;
      // min-height: 50vh;
    }
    @media (max-width: $mobileScreen) {
      padding-left: 0px;
      padding-bottom: 0;
      padding-bottom: 100px;
    }
    .list {
      scroll-margin-top: 270px;
    }
    img {
      // max-width: 460px;
      @media (max-width: $tabletScreen) {
        max-width: 100%;
      }
      @media (max-width: $tabletScreen) {
        // max-width: 251px;
      }
    }
  }
  section:nth-of-type(5) {
    height: 500px;
    @media (max-width: $mobileScreen) {
      height: auto;
    }
  }
  // section:nth-of-type(1) {
  //   display: block;
  // }
  // .sectionActive {
  //   display: block;
  // }
  .sectionA {
    // margin-bottom: 40px;
  }
  .sectionNotActive {
    display: none;
  }
}

.sections section:nth-child(even) {
  // margin-left: 124px;
  @media (max-width: $tabletScreen) {
    margin-left: 0px;
  }
}

.sectionClientHome {
  padding: 100px 0 0 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0 0 0;
  }
  h3 {
    @extend %subTitle;
    margin-bottom: 80px;
    text-align: center;
    @media (max-width: $tabletScreen) {
      margin-bottom: 64px;
    }
  }
  .wrapListClientHome {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 0;
      padding: 0;
      grid-gap: 88px 179px;
      @media (max-width: $tabletScreen) {
        grid-gap: 88px 118px;
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (max-width: $mobileScreen) {
        grid-gap: 64px 20px;
        grid-template-columns: 1fr 1fr;
      }
      li {
        list-style: none;
        img {
          width: 100%;
        }
      }
    }
  }
  a {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: 90px;
    button {
      @extend %button;
      min-width: 166px;
    }
    button:hover {
      background-color: $colorHover;
    }
  }
}

.sectionThinkHome {
  padding: 103px 0 0px 0;
  padding-bottom: 100px;
  @media (max-width: $tabletScreen) {
    padding-bottom: 80px;
  }
  @media (max-width: $mobileScreen) {
    padding-bottom: 105px;
  }
  h3 {
    @extend %subTitle;
    text-align: center;
    margin-bottom: 39px;
  }
  a {
    display: block;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    margin-top: 40px;
    button {
      @extend %button;
      h3 {
        margin-bottom: 0px;
      }
    }
    button:hover {
      background-color: $colorHover;
    }
    .ItemThinkHome {
      img {
        width: 100%;
        margin-bottom: 30px;
      }
      h2 {
        text-align: left;
        color: $colorBlack;

        @extend %H3Desktop;
      }
      p {
      }
    }
  }
}

.sectionTitlePage {
  padding: 130px 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0;
  }
  h2 {
    @extend %H1Desktop;
    margin-top: 10px;
    // margin-bottom: 30px;
  }
  h3 {
    @extend %subTitle;
    margin-bottom: 20px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 33px;
    }
    @media (max-width: $mobileScreen) {
      margin-bottom: 25px;
    }
  }
  .titleWorkInfo {
    // p {
    //   @extend %H1Desktop;
    //   span {
    //     @extend %H1Desktop;
    //   }
    // }
  }
}

.sectionTitleWorksInfo {
  padding: 80px 0 140px 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0;
  }
  h2 {
    margin-bottom: 40px;
  }
  h5 {
    @extend %bodyDesktop;
  }
  h4 {
    @extend %bodyDesktop;
    @media (max-width: $tabletScreen) {
      margin-top: 7px;
      margin-bottom: 38px;
    }
  }
  .wrapObjectiveWorks {
    margin-top: 36px;
    h4 {
      margin-bottom: 0;
    }
  }
}

.sectionListWorks {
  padding-bottom: 40px;
  @media (max-width: $tabletScreen) {
    margin-bottom: 32px;
  }
  a {
    color: $colorBlack;
    text-decoration: none;
  }
  .ItemListWorks {
    margin-bottom: 40px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 32px;
    }
    img {
      width: 100%;
    }
    h2 {
      @extend %H3Desktop;
      margin-top: 40px;
      margin-bottom: 20px;
      @media (max-width: $tabletScreen) {
        margin-top: 32px;
        margin-bottom: 16px;
        font-size: 28px;
      }
      @media (max-width: $mobileScreen) {
        font-size: 22px;
      }
    }
    p {
      @extend %subTitle;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
  button {
    width: 166px;
    color: $colorBlack;
    text-align: center;
    font-family: $fontFrank, $fontHelvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 22px 0;
    background-color: transparent;
    margin: 0 auto;
    line-height: 16px; /* 114.286% */
    margin-top: 90px;
    border: 1px solid $colorBlack;
    // margin-bottom: 40px;
  }
  button:hover {
    background-color: $colorHover;
  }
  h3 {
    @extend %button;
    border: none;
    padding: 0;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 97px;
    @media (max-width: $mobileScreen) {
      margin-bottom: 65px;
    }
  }
}

.sectionTeamWorksInfo {
  padding: 130px 0;
  // padding-bottom: 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0;
    // padding-bottom: 0;
  }
  h3 {
    @extend %subTitle;
    text-align: center;
  }
  .ItemTeamWorks {
    margin-top: 24px;
    h2 {
      @extend %bodyDesktop;
      text-align: center;
      line-height: 11px;
    }
  }
}

.sectionInfoWorks {
  img {
    width: 100%;
    margin: 40px 0;
  }
  // img:first-of-type {
  //   display: none;
  // }
  img:last-of-type {
    margin-bottom: 0;
  }
  .imageBannerBottom {
    margin: 0;
  }
  .imageThumbnail {
    display: block !important;
  }
  h4 {
    @extend %subTitle;
    margin: 40px 0;
  }
  p {
    @extend %bodyDesktop;
  }

  .wrapDescProcess {
    // margin-bottom: 51px;
    h4 {
      margin: 29px 0;
      @extend %subTitle;
    }
    p {
      margin-bottom: 0;
    }
    .textOverAll {
      margin-top: 40px;
    }
  }
}
.NavBar {
  width: 100%;
  height: 20px;
  background-color: red;
}

.Container {
  height: calc(100vh - 20px);
  overflow-y: scroll;
}

.Section {
  height: calc(100vh - 20px);
  border-bottom: 2px solid black;
}

// .header {height: 120px;}
// .container {float:left;}
#containerTh {
  display: none;
}
.containerThActive {
  display: inline;
}

.containerEnActive {
  ul {
    li {
    }
    .enActive {
      color: $colorBlack !important;
      pointer-events: none;
    }
    .thActive {
      color: $colorGray !important;
      display: block !important;
    }
    .thActiveLeft {
      display: none !important;
    }
  }
}
.overlay-right {
  display: none;
  background: red;
  float: right;
  height: 100%;
}
.right-panel-active ~ .overlay-right {
  display: inline-block;
}
.sectionInfoThink {
  h4 {
    @extend %H3Desktop;
    margin: 0 0 40px;
    @media (max-width: $tabletScreen) {
      margin: 0 0 32px;
      font-size: 28px;
    }
  }
  .textInfo {
    p {
      animation: fade-in 3s;
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 100;
      }
    }
  }
  p,
  li {
    @extend %bodyDesktop;
  }
  .imageBannerThinkInfo {
    width: 100%;
  }
  img {
    width: 100%;
    margin-bottom: 40px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 32px;
    }
    @media (max-width: $mobileScreen) {
      margin-bottom: 105px;
    }
  }
}
.sectionInfoCulture {
  padding: 130px 0 130px 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0 105px 0;
  }
  h3 {
    @extend %subTitle;
    margin-bottom: 40px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 21px;
    }
    @media (max-width: $mobileScreen) {
      margin-bottom: 32px;
    }
  }
  .wrapInfoCulture {
    @media (max-width: $mobileScreen) {
      margin-bottom: 105px;
    }
    h2 {
      @extend %H2Desktop;
      @media (max-width: $tabletScreen) {
        margin-bottom: 32px;
        font-size: 40px;
      }
      @media (max-width: $mobileScreen) {
        font-size: 24px;
      }
    }
    h4 {
      @extend %bodyDesktop;
    }
    img {
      width: 100%;
      margin: 150px auto;
      @media (max-width: $tabletScreen) {
        margin: 105px auto;
      }
      @media (max-width: $mobileScreen) {
        margin: 0px;
      }
    }
  }
  .wrapInfoCultureBottom {
    h2 {
      @media (max-width: $tabletScreen) {
        margin-bottom: 105px;
      }
    }
    h3 {
      margin-bottom: 14px;
    }
  }
}
.sectionInfoContact {
  padding: 130px 0 130px 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0 105px 0;
  }
  @media (max-width: $mobileScreen) {
    // padding: 64px 0 0 0;
  }
  h3 {
    @extend %subTitle;
    margin-bottom: 40px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 18px;
    }
  }
  h1 {
    @extend %H1Desktop;
    @media (max-width: $tabletScreen) {
      margin-bottom: 32px;
    }
  }
  h2 {
    @extend %bodyDesktop;
    margin-bottom: 38px;
  }
  form {
    margin-top: 40px;
    margin-bottom: 130px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 105px;
    }
    @media (max-width: $mobileScreen) {
      margin-bottom: 45px;
    }
    input {
      width: 100%;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      font-family: $fontHelvetica;
      border: none;
      background-color: transparent;
      // border-bottom: 1px solid $colorBlack;
      line-height: 31.2px;
      // padding-bottom: 9px;
      // margin-bottom: 24px;
    }
    textarea {
      height: 225px;
      width: 100%;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.4px;
      font-family: $fontHelvetica;
      border: none;
      background-color: transparent;
      // border-bottom: 1px solid $colorBlack;
      line-height: 31.2px;
    }
    p {
      @extend %pDeskTop;
      margin-top: 10px;
    }
    .btnSubmit {
      background-color: $colorBlack;
      height: 60px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      color: $colorWhite;
      margin-top: 20px;
      font-family: $fontFrank, $fontHelvetica;
    }
    .btnSubmit:hover {
      background-color: #9c9c9c;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    min-height: 220px;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    @media (max-width: $tabletScreen) {
      height: 408px;
      margin-top: 105px;
    }
    @media (max-width: $mobileScreen) {
      height: 299px;
      margin-top: 74px;
      // padding-bottom: 100%;
    }
  }

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }
  label[data-shrink="false"]
    + .MuiInputBase-formControl
    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    // width: 100%;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    font-family: $fontHelvetica;
    border: none;
    background-color: transparent;
    // border-bottom: 1px solid $colorBlack;
    line-height: 31.2px;
    padding-bottom: 9px;
    // margin-bottom: 24px;
  }
  .css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid $colorBlack;
    color: $colorBlack;
  }

  .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 24px;
    font-family: $fontHelvetica;
    text-transform: capitalize;
    color: $colorDarkGray;
  }

  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: $colorBlack;
  }

  .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: $colorBlack;
  }

  label + .css-v4u5dn-MuiInputBase-root-MuiInput-root {
    margin-bottom: 16px;
  }

  .wrapContactLink {
    margin-top: 40px;
    button {
      background-color: transparent;
      border: none;
      color: $colorBlack;
      @extend %bodyDesktop;
    }
    h3 {
      margin-bottom: 4px;
      @media (max-width: $tabletScreen) {
        margin-bottom: 18px;
      }
    }
  }
}
.sectionListCareers {
  padding: 130px 0 130px 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0 105px 0;
  }
  h3 {
    @extend %subTitle;
    margin-bottom: 20px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 0px;
    }
  }
  h1 {
    @extend %H1Desktop;
    margin-bottom: 84px;
  }
  a {
    color: $colorBlack;
    text-decoration: none;
  }
  .wrapItemListCareers {
    padding: 34px 0 40px 0;
    border-bottom: 1px solid $colorGray979797;
    @media (max-width: $tabletScreen) {
      padding: 30px 0 30px 0;
    }
    @media (max-width: $mobileScreen) {
      padding: 23px 0;
    }
    .ItemListCareers {
      h3 {
        @media (max-width: $tabletScreen) {
          margin-bottom: 32px;
        }
      }
    }
    h2 {
      @extend %H2Desktop;
      @media (max-width: $tabletScreen) {
        margin-bottom: 32px;
        font-size: 40px;
      }
      @media (max-width: $mobileScreen) {
        font-size: 24px;
      }
    }
    p {
      @extend %bodyDesktop;
    }
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 10px !important;
      margin: 0;
      padding: 0;
      @media (max-width: $tabletScreen) {
        grid-template-columns: 1fr;
      }
      li {
        @extend %bodyDesktop;
        list-style: none;
        p {
          margin-bottom: 0;
        }
      }
    }
    a {
      color: $colorBlack;
      text-decoration: none;
    }
  }
  .wrapItemListCareersContact {
    border-bottom: 0px;
  }
}

.sectionInfoCareers {
  padding: 130px 0 130px 0;
  @media (max-width: $tabletScreen) {
    padding: 105px 0 105px 0;
  }
  h3 {
    @extend %subTitle;
    margin-bottom: 40px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 32px;
    }
  }
  .wrapItemInfoCareer {
    @media (max-width: $tabletScreen) {
      margin-bottom: 32px;
    }
    h3 {
      margin-bottom: 19px;
    }
    h2 {
      @extend %H2Desktop;
      @media (max-width: $tabletScreen) {
        font-size: 54px;
        line-height: 64px;
        margin-bottom: 32px;
      }
      @media (max-width: $mobileScreen) {
        font-size: 24px;
      }
    }
    h4 {
      @extend %bodyDesktop;
      margin-bottom: 40px;
      @media (max-width: $tabletScreen) {
        margin-bottom: 32px;
      }
      p {
        @extend %bodyDesktop;

        @media (max-width: $tabletScreen) {
          font-size: 22px;
        }
        @media (max-width: $mobileScreen) {
          font-size: 20px;
        }
      }
    }
    p {
      margin-bottom: 40px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      @media (max-width: $tabletScreen) {
        margin-bottom: 32px;
      }
    }
  }
  .btnApply {
    width: 100%;
    background-color: transparent;
    border: 1px solid $colorBlack;
    color: $colorBlack;
    border-radius: 0px;
    padding: 23px 0;
    font-family: $fontFrank, $fontHelvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    background-color: #9c9c9c;
  }
  button:hover {
    background-color: #9c9c9c;
    border: 1px solid $colorBlack;
  }
}

.modal .modal-content form .btnSubmit:hover {
  background-color: #9c9c9c;
}

.appFooter {
  background-color: $colorBlack;
  color: $colorWhite;
  padding: 40px 0;
  // margin-top: 100px;
  // @media (max-width: $tabletScreen) {
  //   margin-top: 80px;
  // }
  // @media (max-width: $mobileScreen) {
  //   margin-top: 105px;
  // }
  a {
    color: $colorWhite;
    text-decoration: none;
    @extend %bodyDesktop;
    @media (max-width: $tabletScreen) {
      font-size: 22px;
    }
    @media (max-width: $mobileScreen) {
      margin-bottom: 28px;
      font-size: 20px;
      display: block;
    }
  }
  h3 {
    @extend %subTitle;
    font-size: 16px;
    margin-bottom: 13px;
  }

  .wrapTitleFooter {
    // border-bottom: 1px solid $colorWhite;
    // padding-bottom: 40px;
    margin-bottom: 40px;
    h2 {
      @extend %H3Desktop;
      @media (max-width: $tabletScreen) {
        font-size: 28px;
      }
      @media (max-width: $mobileScreen) {
        font-size: 22px;
      }
    }
    hr {
      background-color: $colorWhite;
      margin-top: 40px;
      margin-bottom: 0;
    }
    button {
      width: 100%;
      background-color: $colorWhite;
      color: $colorBlack;
      font-size: 14px;
      line-height: 16px;
      font-family: $fontFrank, $fontHelvetica;
      padding: 22px 0;
      border-radius: 0px;
      @media (max-width: $mobileScreen) {
        margin-top: 17px;
      }
    }
    button:hover {
      background-color: #9c9c9c;
    }
  }
  .wrapSocialFooter {
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      // gap: 41px;
      justify-content: space-between;
      @media (max-width: $tabletScreen) {
        gap: 41px;
        justify-content: flex-start;
      }
      @media (max-width: $mobileScreen) {
        gap: 41px;
        justify-content: space-between;
      }
      li {
        list-style: none;
        a {
          @media (max-width: $mobileScreen) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .wrapAdressFooter {
    display: flex;
    gap: 11px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 36px;
    }
    @media (max-width: $mobileScreen) {
      margin-bottom: 28px;
    }
    h4 {
      @extend %bodyDesktop;
      @media (max-width: $tabletScreen) {
        font-size: 22px;
      }
      @media (max-width: $mobileScreen) {
        font-size: 20px;
      }
      margin-bottom: 0;
    }
  }
  .wrapTermAndCoppyRight {
    @media (max-width: $mobileScreen) {
      margin-top: 30px;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 200% */
      text-transform: capitalize;
      font-family: $fontFrank, $fontHelvetica;
      @media (max-width: $mobileScreen) {
        text-align: center;
        margin-top: 0;
      }
    }
    .wrapTermFooter {
      ul {
        margin: 0;
        padding: 0;
        @media (max-width: $mobileScreen) {
          justify-content: center;
        }
        li {
          list-style: none;
          a {
            font-family: $fontFrank, $fontHelvetica;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 200% */
            text-transform: capitalize;
            color: $colorWhite;
          }
        }
      }
    }
  }
  .wrapTermFooter {
    margin-top: 40px;
    ul {
      display: flex;
      gap: 10px;
      li {
        a {
          @media (max-width: $mobileScreen) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  p {
    text-align: right;
    margin-top: 40px;
  }
}

.titlu {
  opacity: 0;
  transition: opacity 1s ease;
}

.fadeIn {
  opacity: 1;
}
.modal {
  background-color: $colorBlack;
  .modal-dialog {
    background-color: $colorBlack;
    .modal-content {
      background-color: $colorBlack;
      text-align: center;
      h2 {
        @extend %subTitle;
        color: $colorWhite;
        margin-bottom: 26px;
        @media (max-width: $tabletScreen) {
          margin-bottom: 15px;
        }
      }
      h1 {
        @extend %H1Desktop;
        color: $colorWhite;
        margin-bottom: 40px;
        @media (max-width: $tabletScreen) {
          margin-bottom: 80px;
        }
      }
      a {
        @extend %subTitle;
        color: $colorWhite;
        margin-bottom: 89px;
        display: block;
        @media (max-width: $tabletScreen) {
          margin-bottom: 80px;
        }
      }
    }
  }
  .btnClosePopup {
    background-color: transparent;
    border: none;
    position: fixed;
    top: 61px;
    right: 143px;
    .btnClose {
      width: 33px;
      height: 33px;

      .lineClose {
        width: 33px;
        height: 4px;
        background-color: $colorWhite;
        position: absolute;
      }
      .lineClose:nth-of-type(1) {
        top: 10px;
        transform: rotate(45deg);
      }
      .lineClose:nth-of-type(2) {
        top: 10px;
        transform: rotate(-45deg);
      }
    }
  }
  .modal {
    @media (max-width: $mobileScreen) {
      position: relative;
    }
  }
  .modal-content {
    .btnClosePopup {
      @media (max-width: $mobileScreen) {
        top: 20px;
        right: 20px;
        width: 24px;
        height: 24px;
        z-index: 2;
      }
    }
    .wrapFormCareer {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 558px;
      @media (max-width: $mobileScreen) {
        // position: static;
        // transform: translate(0);
        padding: 0px 30px;
        min-width: 100%;
        // padding-top: 50px;
      }
    }
    form {
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $colorWhite;
        color: $colorWhite;
        margin-bottom: 24px;
        padding-bottom: 9px;
        outline: 0px;
        box-shadow: none;
        position: relative;
      }
      #phone_number {
        @media (max-width: $tabletScreen) {
          margin-bottom: 50px;
        }
      }
      input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $colorGray;

        opacity: 1; /* Firefox */
      }

      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $colorGray;
      }

      input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $colorGray;
      }
      #idCareer {
        display: none;
      }
      .textFile {
        width: 100%;
        border: 1px solid $colorWhite;
        font-family: $fontFrank, $fontHelvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        height: 60px;
        margin: 0 auto;
        margin-bottom: 51px;
        color: $colorWhite;
        padding-top: 20px;
      }
      #cv {
        opacity: 0;
        display: none;
      }
      .btnSubmit {
        width: 100%;
        border: 1px solid $colorWhite;
        font-family: $fontFrank, $fontHelvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        width: 166px;
        height: 60px;
        margin: 0 auto;
      }
      button:hover {
        background-color: #9c9c9c;
      }
    }
    .wrapApplyDone {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 558px;
      p {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        color: $colorWhite;
        font-family: $fontHelvetica;
      }
    }
    .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
      color: $colorWhite;
    }
    .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
      color: $colorWhite;
    }
    .css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
      border: none;
    }
    .textRequire {
      position: absolute;
      right: 0;
      color: #888;
    }
  }
}

.navigation {
  background-color: rgb(200, 242, 251);
  width: max-content;
  display: block;
  padding: 0.2rem 0.6rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: 4rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
}
.navigation ul {
  display: flex;
  width: 350px;
}
.navigation ul li {
  position: relative;
  list-style: none;
  width: 70px;
  height: 70px;
  z-index: 1;
}
.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.navigation ul li .icon {
  position: relative;
  display: block;
  line-height: 75px;
  font-size: 1.7em;
  text-align: center;
  transition: 0.5s;
  color: #04499d;
}
.navigation ul li.active a .icon {
  transform: translateY(-40px);
  font-size: 1.7em;
  color: rgb(116, 204, 236);
}
.navigation ul li a .text {
  position: absolute;
  color: black;
  font-weight: 600;
  font-size: 1.05em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}
.navigation ul li.active a .text {
  opacity: 1;
  transform: translateY(10px);
  font-size: 0.9em;
  color: black;
  font-weight: 600;
}
.indicator {
  position: absolute;
  top: -50%;
  width: 70px;
  height: 70px;
  background: #032a59;
  border-radius: 50%;
  border: 6px solid var(--clr);
  transition: 0.5s;
}
.indicator::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -22px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 1px -10px 0 0 black;
}
.indicator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -20px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-left-radius: 20px;
  box-shadow: -1px -10px 0 0 black;
}

.navigation ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(70px * 0));
}
.navigation ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(70px * 1));
}
.navigation ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(70px * 2));
}
.navigation ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(70px * 3));
}
.navigation ul li:nth-child(5).active ~ .indicator {
  transform: translateX(calc(70px * 4));
}

.sectionListThink {
  .ItemListWorks {
    p {
      margin-top: 38px;
      margin-bottom: 0;
      @media (max-width: $tabletScreen) {
        margin-top: 32px;
      }
    }
    h2 {
      margin-top: 5px;
    }
  }
}

.sectionShareThinkInfo {
  padding-bottom: 0;
  .wrapBtnShareInfoThink {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 26px;
  }
}

.sectionInfoServices {
  padding-top: 130px;
  padding-bottom: 130px;

  @media (max-width: $tabletScreen) {
    padding-top: 105px;
    padding-bottom: 105px;
  }
  h1 {
    @extend %H1Desktop;
  }
  h3 {
    @extend %subTitle;
    margin-bottom: 25px;
  }
  hr {
    margin: 58px 0;
    background-color: $colorGray979797;
  }
  h4 {
    @extend %H3Desktop;
  }
  .listServices {
    margin-bottom: 100px;
    @media (max-width: $tabletScreen) {
      margin-bottom: 0px;
    }
    .wrapInfoServices {
      @media (max-width: $tabletScreen) {
        margin-bottom: 50px;
      }
    }
  }
  p {
    margin-bottom: 0;
    @extend %bodyDesktop;
    @media (max-width: $tabletScreen) {
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
    }
    @media (max-width: $mobileScreen) {
      font-size: 22px;
    }
  }
  ul {
    padding-inline-start: 40px;
    margin-bottom: 0;
    padding: 0 40px !important;
    @media (max-width: $mobileScreen) {
      padding: 0 40px !important;
      padding-right: 0px !important;
    }
    li {
      list-style: disc;
      // list-style-type: none;
      @extend %bodyDesktop;
      position: relative;
    }
    // li::before {
    //   position: absolute;
    //   left: -0.8em;          /* Adjust this value so that it appears where you want. */
    //   font-size: 1.1em;      /* Adjust this value so that it appears what size you want. */
    // }
    li::marker {
      font-size: 1rem;
    }
  }
  .wrapInfoServices {
    @media (max-width: $tabletScreen) {
      // margin-bottom: 50px;
    }
    h4 {
      @extend %bodyDesktop;
      @media (max-width: $tabletScreen) {
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 50px;
      }

      @media (max-width: $mobileScreen) {
        font-size: 22px;
      }
      ul {
        li {
          list-style: disc;
        }
      }
    }
    p {
      @media (max-width: $tabletScreen) {
        margin-bottom: 0px;
      }
    }
  }
  .wrapBtn {
    text-align: center;
    border-bottom: 1px solid $colorGray979797;
    margin-bottom: 50px;
    button {
      @extend %button;
      background-color: $colorBlack;
      color: $colorWhite;
      margin: 0 auto;
      margin-bottom: 102px;
      width: 100%;
      max-width: 265px;
      @media (max-width: $tabletScreen) {
        margin-bottom: 50px;
        max-width: 334px;
      }
      @media (max-width: $mobileScreen) {
        max-width: 100%;
      }
    }
    button:hover {
      background-color: $colorHover;
    }
  }
}

.heading {
  font-size: 3.2rem;

  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 720px) {
    font-size: 2rem;
  }
}

.rocket {
  display: inline-block;
}

.animateRocket {
  animation-duration: 5s;
  animation-name: animate-rocket;
}

@keyframes animate-rocket {
  0% {
    transform: scale(1) rotate(10deg);
  }
  5% {
    transform: scale(1.25) rotate(-15deg);
  }
  10% {
    transform: scale(1.5) rotate(5deg);
  }
  15% {
    transform: scale(1.75) rotate(-10deg);
  }
  20% {
    transform: scale(2) rotate(0);
  }
  100% {
    transform: scale(20) translate3D(100vw, -100vh, 0) rotate(0);
  }
}

.example-style {
  animation: fade-in 3s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.css-i44wyl {
  width: 100%;
}

.MuiFormControl-root {
  width: 100%;
}

.sectionTitleThinkInfo {
  h3 {
    margin-bottom: 33px;
  }
  .titleCate {
    // margin-bottom: 0;
    @media (max-width: $tabletScreen) {
      margin-bottom: 0;
    }
  }
  h2 {
    margin-top: 33px;
    @media (max-width: $tabletScreen) {
      margin-top: 13px;
    }
  }
}

.css-zun73v.Mui-checked,
.css-zun73v.MuiCheckbox-indeterminate {
  color: $colorBlack !important;
}

.css-1rb63tl-MuiInputBase-root-MuiInput-root:before {
  // border-bottom: none !important;
}

label + .css-1rb63tl-MuiInputBase-root-MuiInput-root {
  margin-top: 0 !important;
}

.css-repss6::after {
  border-bottom: 2px solid $colorBlack !important;
}
.css-348fk2.Mui-focused {
  color: $colorBlack !important;
}

.css-zrqpq1::after {
  border-bottom: 2px solid $colorBlack !important;
}

.cwc-cookie-banner-ui-sdk .cwc-css-1con02n-cwc {
  background-color: $colorBlack;
}

.cwc-cookie-banner-ui-sdk .cwc-css-ta2nsy-cwc {
  font-family: $fontFrank;
  color: $colorWhite;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.cwc-banner-desc {
  opacity: 0;
  display: none;
}

.cwc-cookie-banner-ui-sdk .cwc-css-djvxxq-cwc {
  opacity: 0;
  display: none;
}
.cwc-cookie-banner-ui-sdk .cwc-css-1con02n-cwc {
  flex-direction: row !important;
  align-items: center;
}

.cwc-cookie-banner-ui-sdk .cwc-css-7pefdm-cwc {
  max-width: 300px !important;
  min-height: auto !important;
}

.cwc-cookie-banner-ui-sdk .cwc-css-1j5yw2a-cwc {
  padding-top: 0 !important;
}

.cwc-cookie-banner-ui-sdk .cwc-css-1con02n-cwc {
  padding: 22px !important;
}
.cwc-cookie-banner-ui-sdk .cwc-css-8crc6f-cwc {
  background-color: transparent !important;
  border: none !important;
  font-family: $fontFrank;
  min-width: auto !important;
  color: $colorWhite;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 16px !important;
  height: auto !important;
  padding: 0 !important;
}

.cwc-cookie-banner-ui-sdk .cwc-css-nfp235-cwc-cwc {
  background-color: transparent !important;
  border: none !important;
  font-family: $fontFrank;
  color: $colorWhite;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 16px !important;
  min-width: auto !important;
  height: auto !important;
  margin: 0px 0px 0px 0px !important;
  align-items: left;
  border-left: 1px solid $colorWhite !important;
}

.cwc-cookie-banner-ui-sdk .cwc-css-1con02n-cwc {
  min-height: 100% !important;
}

.sectionInfoPolicy {
  p {
    @extend %pDeskTop;
  }
}

.cwc-cookie-banner-ui-sdk .cwc-css-1r4gvee-cwc {
  flex-direction: row !important;
  min-height: auto !important;
}

.modal.fade .modal-dialog {
  transition: none !important;
}

.fade {
  transition: none !important;
}

.hide-scroll {
  overflow: hidden;
}

.css-ahj2mt-MuiTypography-root {
  font-family: $fontHelvetica !important;
}

.css-9l3uo3 {
  font-family: $fontHelvetica !important;
}

.css-mqt2s5 {
  font-family: $fontHelvetica !important;
}
.css-zrqpq1 {
  font-family: $fontHelvetica !important;
}

.css-348fk2 {
  font-family: $fontHelvetica !important;
}

.css-1d1r5q-MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
  position: absolute;
  right: 0;
  top: 31px;
  bottom: 0;
  margin-top: 0;
  width: 17px;
  height: 17px;
  background-color: #d32f2f;
  border-radius: 100%;
}

.wrapInputEmail {
  position: relative;
}

.wrapFormCareer {
  .css-348fk2 {
    color: $colorWhite !important;
  }
  .css-348fk2.Mui-focused {
    color: $colorWhite !important;
  }
  .css-mqt2s5 {
    color: $colorWhite !important;
  }
  .css-mqt2s5.Mui-focused {
    color: $colorWhite !important;
  }
}


.sectionListWorksRecommen {
  .listItem:last-child {
    
    @media (max-width: $mobileScreen) {
      display: none;
    }
  }
}

.css-j7o63n.Mui-error {
  color: #d32f2f;
  position: absolute;
  right: 0;
  top: 31px;
  bottom: 0;
  margin-top: 0;
  width: 17px;
  height: 17px;
  background-color: #d32f2f;
  border-radius: 100%;
}

.css-mnn31 {
  position: relative;
}